import React from 'react';
import { render } from "react-dom"
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Privacy from './pages/Privacy';
import ErrorPage from './pages/Error'


const rootElement = document.getElementById('root')

render(<HashRouter>
<Routes>
<Route path="/" element={<App />} />
<Route path="privacy" element={<Privacy />} />
<Route path="*" element={<ErrorPage />} />
</Routes>
</HashRouter>, rootElement)