import Button from '@mui/material/Button'
import { SvgIcon } from '@mui/material';
import useDocumentTitle from '../useDocumentTitle';

function Privacy() {
  useDocumentTitle("Privacy - BetterSEQTA")
  return (
    <div className="App">
    <header className="App-header">
    <h1 style={{marginBottom: "8px"}}>Privacy Policy</h1>
    <Button
           variant="contained"
           style={ {background: "#4c8bf5", margin: "5px", width: "23em"} }
           href="https://chrome.google.com/webstore/detail/betterseqta/boikofabjaholheekefimfojfncpjfib"
           startIcon={
             <SvgIcon>
               <path fill="currentColor" d="M12,20L15.46,14H15.45C15.79,13.4 16,12.73 16,12C16,10.8 15.46,9.73 14.62,9H19.41C19.79,9.93 20,10.94 20,12A8,8 0 0,1 12,20M4,12C4,10.54 4.39,9.18 5.07,8L8.54,14H8.55C9.24,15.19 10.5,16 12,16C12.45,16 12.88,15.91 13.29,15.77L10.89,19.91C7,19.37 4,16.04 4,12M15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12M12,4C14.96,4 17.54,5.61 18.92,8H12C10.06,8 8.45,9.38 8.08,11.21L5.7,7.08C7.16,5.21 9.44,4 12,4M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                />
             </SvgIcon>
           }
           >Chrome Extension</Button>
    <ul style={{fontSize: "20px"}}>
      <li>BetterSEQTA doesn't collect or store any personal information.</li>
      <li>No data is transmitted to anywhere, ever.</li>
    </ul>
    </header>
    </div>
  );
}

export default Privacy;
