import useDocumentTitle from '../useDocumentTitle';

function ErrorPage() {
  useDocumentTitle("404 - This page could not be found.")
  return (
    <div className="App">
    <header className="App-header">
    <div style={{display: 'flex', alignItems: 'center'}}><h1 style={{marginRight: "15px"}}>404</h1> <p> | This page could not be found.</p></div>
    </header>
    </div>
  );
}

export default ErrorPage;
